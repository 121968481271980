import { createAsyncThunk, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { apiRequest } from '../../api';
import { CreateUserDto, UpdateUserDto, User, UserState } from './userTypes';
import { saveStateToLocalStorage, loadStateFromLocalStorage, removeStateFromLocalStorage } from '../../localStorage';

const initialState: UserState = {
  user: null,
  isLoading: false,
  error: null,
};

const persistedUserState = loadStateFromLocalStorage('userState') || initialState;

export const createUser = createAsyncThunk('user/createUser', async (userData: CreateUserDto) => {
  const response = await apiRequest({ url: 'users/create', method: 'POST', body: userData });
  return response as User;
});

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await apiRequest({ url: 'users', method: 'GET' });
  return response as User;
});

export const fetchUserById = createAsyncThunk('user/fetchUserById', async (userId: number) => {
  const response = await apiRequest({ url: `users/${userId}`, method: 'GET' });
  return response as User;
});

export const updateUser = createAsyncThunk('user/updateUser', async (userData: UpdateUserDto) => {
  const response = await apiRequest({ url: 'users', method: 'PATCH', body: userData });
  return response as User;
});

export const updateUserById = createAsyncThunk('user/updateUserById', async ({ userId, userData }: { userId: number, userData: UpdateUserDto }) => {
  const response = await apiRequest({ url: `users/${userId}`, method: 'PATCH', body: userData });
  return response as User;
});

export const setActive = createAsyncThunk('user/setActive', async (isActive: boolean) => {
  await apiRequest({ url: 'users/active', method: 'PATCH', body: { isActive } });
});

export const deleteUserById = createAsyncThunk('user/deleteUserById', async (userId: number) => {
  const response = await apiRequest({ url: `users/${userId}`, method: 'DELETE' });
  return userId;
});

export const deleteUser = createAsyncThunk('user/deleteUser', async () => {
  const response = await apiRequest({ url: 'users', method: 'DELETE' });
  return response;
});

const userSlice = createSlice({
  name: 'user',
  initialState: persistedUserState,
  reducers: {
    clearUserState: (state) => {
      Object.assign(state, initialState);
      removeStateFromLocalStorage('userState');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state, action) => {
        state.doctors.push(action.payload);
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        saveStateToLocalStorage('userState', state);
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUserById.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(deleteUserById.fulfilled, (state, action) => {
        if (state.user?.id === action.payload) {
          state.user = null;
        }
        state.doctors = state.doctors?.filter((user : User) => user.id !== action.payload) || [];
        state.patients = state.patients?.filter((user : User) => user.id !== action.payload) || [];
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.user = null;
      })
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.isLoading = false;
        state.error =  'An error occurred';
      })
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        (state) => {
          state.isLoading = false;
          state.error = null;
        }
      );
  },
});

export const { clearUserState } = userSlice.actions;

export default userSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveStateToLocalStorage, loadStateFromLocalStorage } from "../../localStorage";
import { NavState } from "./navTypes";
import { User } from "../user/userTypes";

const initialState: NavState = loadStateFromLocalStorage("navState") || {
  dashboard: "TodayAppointment",
  setting: "Profile",
  menu: "Dashboard",
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setDashboard: (state, action: PayloadAction<NavState["dashboard"]>) => {
      state.dashboard = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    setSetting: (state, action: PayloadAction<NavState["setting"]>) => {
      state.setting = action.payload;
      saveStateToLocalStorage("navState", state);
    },
    //set current menu bar
    setMenu: (state, action: PayloadAction<NavState["menu"]>) => {
      state.menu = action.payload;
      saveStateToLocalStorage("navState", state);
    },
  },
});

export const { setDashboard, setSetting, setMenu } = navSlice.actions;
export default navSlice.reducer;

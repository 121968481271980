import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import TopNavBar from "../../components/nav/TopNavBar";
import PasswordEntry from "../../components/entry/PasswordEntry";
import { loginInLocal } from "../../redux/features/auth/authSlice";
import { AppDispatch, RootState } from "../../redux/store";

const SignIn: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, error } = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsFormValid(
      email.includes("@") && email.includes(".") && password.length > 0
    );
  }, [email, password]);

  useEffect(() => {
    if (error) {
      setErrorMessage("Incorrect ID or password. Please try again.");
      setEmail("");
      setPassword(""); // Clear password input
    } else {
      setErrorMessage("");
    }
  }, [error]);

  const onSignUpClick = useCallback(() => {
    navigate("/sign-up");
  }, [navigate]);

  const onForgotPasswordClick = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const onLoginBtnContainerClick = useCallback(() => {
    if (isFormValid) {
      dispatch(loginInLocal({ email, password }));
    }
  }, [dispatch, email, password, isFormValid]);

  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start gap-[100px] text-center text-sm-7 text-dimgray-200 font-inter">
      <TopNavBar />
      <div className="w-[580px] rounded-[20.49px] bg-white h-[658px] overflow-hidden shrink-0 flex flex-col items-center justify-start pt-[100px] px-0 pb-0 box-border gap-[31px] mix-blend-normal">
        <div className="w-[489px] flex flex-col items-center justify-center">
          <img
            className="w-[94.3px] relative h-[100px] object-cover"
            alt=""
            src="/logo/logo.png"
          />
        </div>
        <div className="w-[480px] h-[319px] flex flex-col items-center justify-between">
          <div className="relative text-[20.5px] font-medium text-gray-600">
            Sign In
          </div>
          {errorMessage && (
            <div className="relative text-red-500 mt-2">{errorMessage}</div>
          )}
          <div className="self-stretch flex flex-col items-start justify-start gap-[3.4px] text-left">
            <div className="relative">Email address</div>
            <TextField
              className="[border:none] bg-[transparent] self-stretch relative"
              color="primary"
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "47.8px" } }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="self-stretch flex flex-col items-end justify-start gap-[6.8px] text-left">
            <PasswordEntry
              passwordEntryText="Password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <div className="self-stretch flex flex-row items-center justify-between py-px px-0 text-gray-600">
              <div
                className="flex-1 relative [text-decoration:underline] cursor-pointer"
                onClick={onSignUpClick}
              >
                Don’t have an account
              </div>
              <div
                className="flex-1 relative [text-decoration:underline] text-right cursor-pointer"
                onClick={onForgotPasswordClick}
              >
                Forget your password
              </div>
            </div>
          </div>
          <button
            className={`cursor-pointer [border:none] p-0 w-[300px] relative rounded-[27.31px] h-[55px] overflow-hidden shrink-0 ${
              isFormValid ? "bg-gray-600" : "bg-gray-400 opacity-50"
            }`}
            onClick={onLoginBtnContainerClick}
            disabled={!isFormValid || isLoading}
          >
            <div className="absolute top-[calc(50%_-_10.9px)] left-[calc(50%_-_27px)] text-lg-8 font-medium font-inter text-white text-center">
              {isLoading ? "Logging in..." : "Log in"}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

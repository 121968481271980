import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, AppDispatch } from "../../redux/store";
import { setMenu } from "../../redux/features/nav/navSlice";
import { NavState } from "../../redux/features/nav/navTypes";
import { UserRole } from "../../redux/features/user/userTypes"; // Adjust the import path if necessary

export type MenuBarType = {
  className?: string;
  onClose?: () => void;
};

const MenuBar: FunctionComponent<MenuBarType> = ({
  className = "",
  onClose,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const currentMenu = useSelector((state: RootState) => state.nav.menu);
  const userRole = useSelector((state: RootState) => state.user.user?.role);

  const handleMenuClick = (menu: NavState["menu"]) => {
    dispatch(setMenu(menu));
    if (menu === "Dashboard") {
      navigate("/");
    } else if (menu === "Settings") {
      navigate("/setting");
    } else if (menu === "PatientAccounts") {
      navigate("/patient-accounts");
    } else if (menu === "DoctorAccounts") {
      navigate("/doctor-accounts");
    } else if (menu === "Patients") {
      navigate("/patients");
    }
  };

  const getButtonClass = (menu: NavState["menu"]) =>
    currentMenu === menu ? "text-blue" : "";

  const getIcon = (menu: NavState["menu"], active: boolean) => {
    const icons = {
      Dashboard: active
        ? "/menu/dashboardicon1.svg"
        : "/menu/dashboardicon.svg",
      Schedule: active ? "/menu/scheduleicon1.svg" : "/menu/scheduleicon.svg",
      PatientAccounts: active
        ? "/menu/patientaccountsicon1.svg"
        : "/menu/patientaccountsicon.svg",
      DoctorAccounts: active
        ? "/menu/doctoraccountsicon1.svg"
        : "/menu/doctoraccountsicon.svg",
      Patients: active
        ? "/menu/patientaccountsicon1.svg"
        : "/menu/patientaccountsicon.svg",
      Appointments: active
        ? "/menu/appointmenticon1.svg"
        : "/menu/appointmenticon.svg",
      History: active ? "/menu/historyicon1.svg" : "/menu/historyicon.svg",
      Analytics: active
        ? "/menu/analyticsicon1.svg"
        : "/menu/analyticsicon.svg",
      Profile: active ? "/menu/profileicon1.svg" : "/menu/profileicon.svg",
      Settings: active ? "/menu/settingicon1.svg" : "/menu/settingicon.svg",
      Support: active ? "/menu/supporticon1.svg" : "/menu/supporticon.svg",
    };
    return icons[menu];
  };

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      { threshold: 0.15 }
    );

    scrollAnimElements.forEach((element) => observer.observe(element));

    return () => {
      scrollAnimElements.forEach((element) => observer.unobserve(element));
    };
  }, []);

  return (
    <div
      className={`w-[254px] h-full relative bg-white box-border overflow-hidden flex flex-col items-center justify-start py-[26px] px-0 gap-[13px] [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-left] opacity-[0] max-w-[90%] text-left text-xs-1 text-button-gray font-roboto border-r-[0.9px] border-solid border-menu-line-grey ${className}`}
      data-animate-on-scroll
    >
      <div className="self-stretch flex flex-col items-start justify-center gap-[10px]">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
          <div className="flex-1 relative">MENU</div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[13px] text-sm-7">
          {[
            "Dashboard",
            // "Schedule", // Temporarily hidden
            // "Appointments", // Temporarily hidden
            // "History", // Temporarily hidden
          ].map((menu) => (
            <button
              key={menu}
              className={`self-stretch h-10 flex flex-row items-center justify-start py-2 px-5 box-border gap-[10px] bg-transparent ${getButtonClass(
                menu as NavState["menu"]
              )}`}
              onClick={() => handleMenuClick(menu as NavState["menu"])}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <img
                className="w-[20.5px] relative h-[20.5px] overflow-hidden shrink-0"
                alt=""
                src={getIcon(menu as NavState["menu"], currentMenu === menu)}
              />
              <div className="relative">{menu}</div>
            </button>
          ))}
          {userRole === UserRole.Admin && (
            <>
              {/* <button
                className={`self-stretch h-10 flex flex-row items-center justify-start py-2 px-5 box-border gap-[10px] bg-transparent ${getButtonClass(
                  "Analytics"
                )}`}
                onClick={() => handleMenuClick("Analytics")}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <img
                  className="w-[20.5px] relative h-[20.5px] overflow-hidden shrink-0"
                  alt=""
                  src={getIcon("Analytics", currentMenu === "Analytics")}
                />
                <div className="relative">Analytics</div>
              </button> */}
              <button
                className={`self-stretch h-10 flex flex-row items-center justify-start py-2 px-5 box-border gap-[10px] bg-transparent ${getButtonClass(
                  "PatientAccounts"
                )}`}
                onClick={() => handleMenuClick("PatientAccounts")}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <img
                  className="w-[20.5px] relative h-[20.5px] overflow-hidden shrink-0"
                  alt=""
                  src={getIcon(
                    "PatientAccounts",
                    currentMenu === "PatientAccounts"
                  )}
                />
                <div className="relative">Patient Accounts</div>
              </button>
              <button
                className={`self-stretch h-10 flex flex-row items-center justify-start py-2 px-5 box-border gap-[10px] bg-transparent ${getButtonClass(
                  "DoctorAccounts"
                )}`}
                onClick={() => handleMenuClick("DoctorAccounts")}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <img
                  className="w-[20.5px] relative h-[20.5px] overflow-hidden shrink-0"
                  alt=""
                  src={getIcon(
                    "DoctorAccounts",
                    currentMenu === "DoctorAccounts"
                  )}
                />
                <div className="relative">Doctor Accounts</div>
              </button>
            </>
          )}
        </div>
        <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[30px]">
          <div className="flex-1 relative box-border h-[0.9px] border-t-[0.9px] border-solid border-menu-line-grey" />
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-center gap-[10px]">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-5">
          <div className="flex-1 relative">GENERAL</div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[13px] text-sm-7">
          {/* {["Profile", "Support"].map((menu) => ( // Temporarily hidden */}
          {["Settings"].map((menu) => (
            <button
              key={menu}
              className={`self-stretch h-10 flex flex-row items-center justify-start py-2 px-5 box-border gap-[10px] bg-transparent ${getButtonClass(
                menu as NavState["menu"]
              )}`}
              onClick={() => handleMenuClick(menu as NavState["menu"])}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <img
                className="w-[20.5px] relative h-[20.5px] overflow-hidden shrink-0"
                alt=""
                src={getIcon(menu as NavState["menu"], currentMenu === menu)}
              />
              <div className="relative">{menu}</div>
            </button>
          ))}
        </div>
        <div className="self-stretch flex flex-row items-center justify-start py-2.5 px-[30px]">
          <div className="flex-1 relative box-border h-[0.9px] border-t-[0.9px] border-solid border-menu-line-grey" />
        </div>
      </div>
    </div>
  );
};

export default MenuBar;

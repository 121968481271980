import { FunctionComponent } from "react";

export type IntroType = {
  className?: string;
};

const Intro: FunctionComponent<IntroType> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-deepskyblue overflow-hidden flex flex-row items-start justify-start gap-2.5 max-w-full text-left text-41xl text-white font-inter mq1950:flex-wrap ${className}`}
    >
      <div className="flex-1 flex flex-col items-end justify-center pt-0 px-0 pb-[103px] box-border relative gap-2.5 min-w-[675px] min-h-[792px] max-w-full mq975:pb-[67px] mq975:box-border mq975:min-w-full mq1950:flex-1">
        <img
          className="w-[1039px] h-[391px] absolute !m-[0] top-[0px] left-[0px] object-cover"
          loading="lazy"
          alt=""
          src="/landing/rectangle-69@2x.png"
        />
        <div className="w-[670px] flex flex-col items-start justify-start py-0 pl-5 pr-0 box-border gap-[34px] max-w-full z-[1] mq975:gap-[17px]">
          <h1 className="m-0 self-stretch relative text-inherit font-semibold font-[inherit] mq450:text-17xl mq975:text-29xl">
            Unlock Your Academic Potential: Experience the Future of Studying
            with AI-Powered Note-Taking
          </h1>
          <div className="w-[581px] relative text-3xl inline-block max-w-full box-border pr-5 mq450:text-lg">
            Study smarter, not harder, with YouWo: your ultimate study partner.
            Our cutting-edge app transforms your study sessions with superior
            audio recording, image-to-text transcription, and advanced AI-driven
            note summary and analysis. By streamlining the process of capturing
            and reviewing lecture content, YouWo elevates your learning
            efficiency to new heights.
          </div>
        </div>
      </div>
      <div className="flex-[0.9038] flex flex-row items-center justify-start py-0 px-[50px] box-border min-w-[675px] max-w-full mq975:pl-[25px] mq975:pr-[25px] mq975:box-border mq975:min-w-full mq1950:flex-1">
        <img
          className="w-[452px] relative max-h-full object-cover max-w-full"
          loading="lazy"
          alt=""
          src="/landing/screen-1-1@2x.png"
        />
      </div>
    </section>
  );
};

export default Intro;

import { FunctionComponent } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
} from "@mui/material";

export type FooterType = {
  className?: string;
};

const Footer: FunctionComponent<FooterType> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch overflow-hidden flex flex-row items-center justify-between py-7 px-[359px] box-border max-w-full gap-5 text-left text-xl text-black font-inter mq450:flex-wrap mq450:justify-center mq450:pl-5 mq450:pr-5 mq450:box-border mq975:flex-wrap mq975:justify-center mq975:pl-[89px] mq975:pr-[89px] mq975:box-border mq1450:flex-wrap mq1450:justify-center mq1450:pl-[179px] mq1450:pr-[179px] mq1450:box-border mq1950:flex-wrap mq1950:justify-center ${className}`}
    >
      <div className="w-[425px] overflow-hidden shrink-0 flex flex-col items-start justify-start gap-2.5 max-w-full">
        <div className="self-stretch relative mq450:text-base">
          Copyright © 2024 YouWo.ai
        </div>
        <div className="self-stretch relative mq450:text-base">
          Powered by YouWo.ai
        </div>
      </div>
      <div className="w-[802px] flex flex-row items-center justify-start gap-5 max-w-full mq1450:flex-wrap">
        <TextField
          className="[border:none] bg-[transparent] h-[67px] flex-1 font-inter font-semibold text-3xl text-darkgray min-w-[300px] max-w-full"
          placeholder="Email Addess"
          variant="outlined"
          sx={{
            "& fieldset": { borderColor: "#cbcbcb" },
            "& .MuiInputBase-root": {
              height: "67px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              fontSize: "22px",
            },
            "& .MuiInputBase-input": { color: "#979797" },
          }}
        />
        <Button
          className="h-[67px] w-52"
          disableElevation
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            fontSize: "22",
            background: "#4ea4f5",
            borderRadius: "10px",
            "&:hover": { background: "#4ea4f5" },
            width: 208,
            height: 67,
          }}
        >
          Subscribe
        </Button>
      </div>
    </section>
  );
};

export default Footer;

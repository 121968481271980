// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import userReducer from './features/user/userSlice';
import navReducer from './features/nav/navSlice';
import { loadStateFromLocalStorage } from './localStorage';

const persistedState = {
  auth: loadStateFromLocalStorage('authState'),
  user: loadStateFromLocalStorage('userState'),
  nav: loadStateFromLocalStorage('navState'),
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    nav: navReducer,
    user: userReducer,
  },
  preloadedState: persistedState,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

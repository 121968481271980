import { createSlice, createAsyncThunk, isRejectedWithValue, isPending } from '@reduxjs/toolkit';
import { apiRequest, apiRequestRefresh } from '../../api';
import { decode as atob } from 'base-64';
import { SignupLocalDto, AuthDto, Tokens, TokensResponse, GoogleOAuthSigninDto, ResetPasswordDto, ForgotPasswordDto } from './authTypes';
import { fetchUser } from '../user/userSlice';
import { saveStateToLocalStorage, loadStateFromLocalStorage, clearLocalStorage } from '../../localStorage';

export interface AuthState {
  isLoggedIn: boolean;
  tokens: Tokens | null;
  isLoading: boolean;
  error: unknown;
}

const persistedAuthState: AuthState = loadStateFromLocalStorage('authState') || {
  isLoggedIn: false,
  tokens: null,
  isLoading: false,
  error: null,
};

function getTokenExpiration(token: string): number {
  try {
    const payload = token.split('.')[1];
    const decoded = atob(payload);
    const { exp } = JSON.parse(decoded);
    if (!exp) {
      throw new Error('No expiration found in token');
    }
    return exp * 1000; // Convert to milliseconds
  } catch (error) {
    console.error('Error extracting token expiration:', error);
    return 0;
  }
}

function convertToTokensWithExpiration(tokensResponse: TokensResponse): Tokens {
  return {
    accessToken: tokensResponse.accessToken,
    refreshToken: tokensResponse.refreshToken,
    accessTokenExpiresAt: getTokenExpiration(tokensResponse.accessToken),
    refreshTokenExpiresAt: getTokenExpiration(tokensResponse.refreshToken),
  };
}

export const signupLocal = createAsyncThunk(
  'auth/signupLocal',
  async (signupData: SignupLocalDto, { rejectWithValue }) => {
    try {
      const useSignupData = { ...signupData, email: signupData.email.toLowerCase() };
      await apiRequest({
        url: 'auth/local/signup',
        method: 'POST',
        body: useSignupData,
        useAuthHeader: false,
      });
      // No tokens are returned, only the signup process is handled
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
    }
  }
);

export const loginInLocal = createAsyncThunk<Tokens, AuthDto>(
  'auth/loginLocal',
  async (loginData, { rejectWithValue }) => {
    try {
      const userLoginData = { ...loginData, email: loginData.email.toLowerCase() };
      const tokensResponse = await apiRequest({
        url: 'auth/local/login',
        method: 'POST',
        body: userLoginData,
        useAuthHeader: false,
      });
      return convertToTokensWithExpiration(tokensResponse);
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
    }
  }
);
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (dto: ForgotPasswordDto, { rejectWithValue }) => {
    try {
      await apiRequest({
        url: 'auth/local/forgot-password',
        method: 'POST',
        body: dto,
      });
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ dto, userId }: { dto: ResetPasswordDto; userId: number }, { rejectWithValue }) => {
    try {
      await apiRequest({
        url: `auth/local/reset-password?userId=${userId}`,
        method: 'POST',
        body: dto,
      });
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
    }
  }
);
export const refreshToken = createAsyncThunk<Tokens, string>(
  'auth/refreshToken',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const tokensResponse = await apiRequestRefresh(refreshToken);
      return convertToTokensWithExpiration(tokensResponse);
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    await apiRequest({ url: 'auth/logout', method: 'POST' });
    return null;
  } catch (error: any) {
    return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
  }
});

export const googleOAuthSignin = createAsyncThunk<Tokens, GoogleOAuthSigninDto>(
  'auth/oauth/google',
  async (oauthData, { rejectWithValue }) => {
    try {
      const tokensResponse = await apiRequest({
        url: 'auth/oauth/google',
        method: 'POST',
        body: oauthData,
        useAuthHeader: false,
      });
      return convertToTokensWithExpiration(tokensResponse);
    } catch (error: any) {
      return rejectWithValue({ message: error.response?.data?.message || 'An unexpected error occurred' });
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: persistedAuthState,
  reducers: {
    clearAuthState: (state) => {
      Object.assign(state, { isLoggedIn: false, tokens: null, isLoading: false, error: null });
      clearLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupLocal.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
        // No changes to tokens or isLoggedIn state
      })
      .addCase(loginInLocal.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.tokens = action.payload;
        state.isLoading = false;
        state.error = null;
        saveStateToLocalStorage('authState', state);
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.tokens = null;
        state.isLoading = false;
        state.error = null;
        clearLocalStorage();
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.tokens = action.payload;
        saveStateToLocalStorage('authState', state);
      })
      .addCase(googleOAuthSignin.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.tokens = action.payload;
        state.isLoading = false;
        state.error = null;
        saveStateToLocalStorage('authState', state);
      })
      .addMatcher(isPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(isRejectedWithValue, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearAuthState } = authSlice.actions;

export default authSlice.reducer;

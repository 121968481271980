// src/api/apiRequest.ts
import axios from 'axios';
import { Store } from 'redux';
import { refreshToken } from '../redux/features/auth/authSlice'; // Adjust import path as necessary
import { RootState } from '../redux/store'; // Adjust the import path as necessary

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
console.log('Server API Endpoint:', API_ENDPOINT);

interface ApiRequestProps {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  type?: string;
  body?: any;
  useAuthHeader?: boolean;
  params?: any;
}

let store: Store<RootState>;

export const injectStore = (_store: Store<RootState>) => {
  store = _store;
};

const apiRequest = async ({ url, method, body, useAuthHeader = true, params }: ApiRequestProps) => {
  if (!store) {
    throw new Error('Store has not been injected');
  }

  const { auth } = store.getState();

  const currentTime = Date.now(); // Current time in milliseconds since epoch
  if (useAuthHeader && auth.tokens && currentTime >= auth.tokens.accessTokenExpiresAt) {
    console.log("refreshing token: ", auth.tokens.refreshToken)
    await store.dispatch(refreshToken(auth.tokens.refreshToken) as any);
  }

  const updatedAuthState = store.getState().auth;

  const headers: Record<string, string> = {};

  if (useAuthHeader && updatedAuthState.tokens) {
    headers['Authorization'] = `Bearer ${updatedAuthState.tokens.accessToken}`;
  }

  try {
    const startTime = performance.now();
    const response = await axios({
      url: `${API_ENDPOINT}${url}`,
      method,
      headers,
      data: body,
      params,
    });
    const endTime = performance.now();
    const fetchTime = endTime - startTime; // Time taken for the request in milliseconds

    console.log(`API request to ${url} took ${fetchTime} milliseconds`);
    return response.data;
  } catch (error: any) {
    throw error || 'An error did not occur during the API request';
  }
};

const apiRequestRefresh = async (refreshToken: string): Promise<any> => {
  try {
    const url = `${API_ENDPOINT}auth/refresh`;
    const response = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshToken}`, // Use refreshToken for authorization
      },
    });
    return response.data;
  } catch (error: any) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

const apiRequestThirdParty = async ({ url, method, type, body, params }: ApiRequestProps) => {
  try {
    const response = await axios({
      url,
      method,
      headers: {
        'Content-Type': type,
      },
      data: body,
      params,
    });
    return response.data;
  } catch (error: any) {
    console.log('Error in apiRequestThirdParty', error);
    throw error;
  }
};

axios.interceptors.request.use(
  request => {
    return request;
  },
  error => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.error('Response Error:', error.response ? JSON.stringify(error.response, null, 2) : error);
    return Promise.reject(error);
  }
);

export { apiRequest, apiRequestRefresh, apiRequestThirdParty };

import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import TopNavBar from "../../components/nav/TopNavBar";
import PasswordEntry from "../../components/entry/PasswordEntry";
import { signupLocal } from "../../redux/features/auth/authSlice";
import { AppDispatch, RootState } from "../../redux/store";

const SignUp: FunctionComponent = () => {
  return (
    <div className="w-full relative shadow-[0px_0px_124px_-15px_rgba(0,_0,_0,_0.25)] rounded-8xs bg-background-grey h-[1080px] overflow-hidden flex flex-col items-center justify-start gap-[100px]">
      <TopNavBar />
      <SignUpView />
    </div>
  );
};

const SignUpView: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isLoading, error } = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      email.includes("@") &&
        email.includes(".") &&
        password.length >= 6 &&
        password === confirmPassword
    );
  }, [email, password, confirmPassword]);

  const onCreateAccBtnClick = useCallback(() => {
    if (isFormValid) {
      const username = email.split("@")[0];
      dispatch(signupLocal({ email, password, username }))
        .unwrap()
        .then(() => {
          navigate("/verify-email");
        })
        .catch((err) => {
          console.error("Signup failed:", err);
        });
    }
  }, [dispatch, email, password, isFormValid, navigate]);

  const onSignInClick = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  return (
    <main className="w-[580px] rounded-[20.49px] bg-white h-[737px] overflow-hidden shrink-0 flex flex-col items-center justify-between pt-[30px] px-0 pb-0 box-border mix-blend-normal text-center text-11xl text-dimgray-200 font-inter">
      <div className="w-[489px] flex flex-col items-center justify-start gap-[6.8px]">
        <img
          className="w-[94.3px] relative h-[100px] object-cover"
          alt=""
          src="/logo/logo.png"
        />
        <h1 className="m-0 w-[561.7px] relative text-inherit font-medium font-inherit flex items-center justify-center">
          Create account
        </h1>
        <div className="flex flex-row items-start justify-start p-0.5 text-left text-base text-gray-600 font-poppins">
          <div className="relative">
            <span className="text-darkslategray-200">
              Already have an account?
            </span>
            <span className="text-dimgray-200">{` `}</span>
            <span
              className="[text-decoration:underline] whitespace-pre-wrap cursor-pointer"
              onClick={onSignInClick}
            >
              Sign in
            </span>
          </div>
        </div>
      </div>
      <div className="w-[480px] flex-1 flex flex-col items-center justify-center gap-[20.5px] text-left text-sm-7">
        <div className="self-stretch flex flex-col items-start justify-start gap-[3.4px]">
          <div className="relative">Email address</div>
          <TextField
            className="[border:none] bg-[transparent] self-stretch relative"
            color="primary"
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "47.8px" } }}
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <div className="w-[82px] relative text-xs-9 text-crimson-200 hidden">
            Error message
          </div>
        </div>
        <PasswordEntry
          passwordEntryText="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <div className="w-[455px] relative text-[15.4px] inline-block">
          Passwords must consist of at least 6 characters.
        </div>
        <PasswordEntry
          passwordEntryText="Password again"
          value={confirmPassword}
          onChange={(e: any) => setConfirmPassword(e.target.value)}
        />
        <button
          className={`cursor-pointer [border:none] p-0 w-[300px] relative rounded-[27.31px] h-[55px] overflow-hidden shrink-0 ${
            isFormValid ? "bg-gray-600" : "bg-gray-400 opacity-50"
          }`}
          onClick={onCreateAccBtnClick}
          disabled={!isFormValid || isLoading}
        >
          <div className="absolute top-[calc(50%_-_10.9px)] left-[calc(50%_-_69.5px)] text-lg-8 font-medium font-inter text-white text-center">
            {isLoading ? "Creating..." : "Create account"}
          </div>
        </button>
        {/* {error && (
          <div className="text-red-500 mt-4">
            {typeof error === "string" ? error : (error as any)?.message}
          </div>
        )} */}
        <div className="w-[180px] relative text-[15.4px] hidden">
          <span>{`Or, continue with `}</span>
          <span className="[text-decoration:underline] text-gray-600">
            Google
          </span>
          <span>
            {` `}
            <span className="[text-decoration:underline]">{` `}</span>
          </span>
        </div>
      </div>
    </main>
  );
};

export default SignUp;

import React from "react";
import { FunctionComponent } from "react";

export type AnnouncementType = {
  className?: string;
};

const Announcement: FunctionComponent<AnnouncementType> = ({
  className = "",
}) => {
  return (
    <footer
      className={`self-stretch overflow-hidden flex flex-row items-center justify-center py-[106px] px-[360px] box-border bg-[url('/public/landing/update@3x.png')] bg-cover bg-no-repeat bg-[top] max-w-full text-left text-3xl text-white font-inter ${className}`}
    >
      <div className="flex-1 flex flex-row items-center justify-start max-w-full">
        <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-2.5 max-w-full">
          <div className="relative font-semibold inline-block max-w-full mq450:text-lg">
            Exciting Update: Coming Soon!
          </div>
          <h1 className="m-0 self-stretch relative text-41xl font-semibold font-[inherit] mq450:text-17xl mq975:text-29xl">
            Your Ultimate Note-Taking App Awaits!
          </h1>
          <div className="self-stretch relative text-xl mq450:text-base">
            Currently under development, our dream app is on the horizon and set
            to revolutionize your study habits. Stay tuned for an exclusive beta
            testing opportunity starting in September!
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Announcement;

import React from "react";
import { FunctionComponent } from "react";

export type Section2Type = {
  className?: string;
};

const Section2: FunctionComponent<Section2Type> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-whitesmoke overflow-hidden flex flex-row flex-wrap items-start justify-end py-0 pl-0.5 pr-0 box-border gap-x-2.5 gap-y-2 max-w-full text-left text-xl text-black font-inter ${className}`}
    >
      <div className="flex-[0.6858] flex flex-col items-end justify-center py-[79px] pl-[345px] pr-[50px] box-border gap-5 min-w-[416px] max-w-full mq450:pl-5 mq450:pt-[33px] mq450:pb-[33px] mq450:box-border mq450:min-w-full mq975:pl-[86px] mq975:pt-[51px] mq975:pb-[51px] mq975:box-border mq1450:pl-[172px] mq1450:pr-[25px] mq1450:box-border">
        <div className="self-stretch relative text-cornflowerblue mq450:text-base">
          AI Boosted
        </div>
        <h1 className="m-0 self-stretch relative text-41xl font-semibold font-[inherit] mq450:text-17xl mq975:text-29xl">
          Unleash the Power of AI: Customized Learning at Its Finest!
        </h1>
        <div className="self-stretch relative text-3xl mq450:text-lg">
          Step into the future of personalized education with YouWo‘s advanced
          AI features. Powered by the latest GPT-4 technology and our own
          customized GPT models, our app is designed to revolutionize the way
          you summarize and comprehend your study material. Our intelligent
          system adapts to your unique learning needs, offering tailored
          summaries and insights that resonate with your personal study habits.
          Whether it’s distilling complex lectures into digestible notes or
          generating creative ideas to explore topics further, our AI does more
          than just summarize—it transforms your educational experience.
          With YouWo, prepare to meet a study assistant that’s as dedicated and
          innovative as you are.
        </div>
      </div>
      <div className="flex-1 flex flex-row items-center justify-start py-[53px] px-[50px] box-border min-w-[416px] max-w-full mq450:pt-[22px] mq450:pb-[22px] mq450:box-border mq450:min-w-full mq975:py-[34px] mq975:px-[25px] mq975:box-border">
        <img
          className="w-[545px] relative max-h-full object-cover max-w-full"
          loading="lazy"
          alt=""
          src="/landing/20240809-122500-1@2x.png"
        />
      </div>
    </section>
  );
};

export default Section2;

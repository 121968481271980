import { FunctionComponent } from "react";

export type Section1Type = {
  className?: string;
};

const Section1: FunctionComponent<Section1Type> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch bg-whitesmoke overflow-hidden flex flex-row flex-wrap items-start justify-start py-0 pl-0 pr-0.5 box-border gap-x-2.5 gap-y-2 max-w-full text-left text-3xl text-black font-inter ${className}`}
    >
      <div className="flex-1 flex flex-col items-end justify-center py-[58.5px] px-[50px] box-border min-w-[416px] max-w-full mq450:pt-[25px] mq450:pb-[25px] mq450:box-border mq450:min-w-full mq975:py-[38px] mq975:px-[25px] mq975:box-border">
        <img
          className="w-[511px] relative max-h-full object-cover max-w-full"
          loading="lazy"
          alt=""
          src="/landing/20240809-122439-1@2x.png"
        />
      </div>
      <div className="flex-1 flex flex-col items-start justify-center py-[68.5px] px-[50px] box-border gap-5 min-w-[416px] max-w-full mq450:pt-[29px] mq450:pb-[29px] mq450:box-border mq450:min-w-full mq975:pt-[45px] mq975:pb-[45px] mq975:box-border mq1450:pl-[25px] mq1450:pr-[25px] mq1450:box-border">
        <div className="w-[567px] relative text-xl text-cornflowerblue inline-block max-w-full mq450:text-base">
          Audio Booster
        </div>
        <h1 className="m-0 w-[639px] relative text-41xl font-semibold font-[inherit] inline-block max-w-full mq450:text-17xl mq975:text-29xl">
          Capture Every Word, Equation, and Insight
        </h1>
        <div className="w-[610px] relative inline-block max-w-full mq450:text-lg">
          With YouWo‘s audio recorder, you’re equipped with an unparalleled tool
          that ensures not a single detail escapes your notes. Our precise
          speech recognition technology flawlessly captures lectures,
          discussions, and any spoken content, converting them into meticulously
          organized notes. But we don’t stop there—our innovative image-to-text
          and image-to-LaTeX features mean that even complex mathematical
          symbols and equations are effortlessly translated into digital text.
          Say goodbye to the hassle of manually writing down intricate symbols
          or equations. With YouWo, your focus remains on learning, while we
          handle the intricacies of note-taking.
        </div>
        <div className="self-stretch flex flex-col items-start justify-start py-0 px-2.5 gap-2.5">
          <div className="flex flex-row items-start justify-start gap-5">
            <img
              className="h-[27px] w-[27px] relative object-cover min-h-[27px]"
              loading="lazy"
              alt=""
              src="/landing/20240809-124049-1@2x.png"
            />
            <div className="relative font-semibold mq450:text-lg">
              Speech Recognition
            </div>
          </div>
          <div className="flex flex-row items-start justify-start gap-5">
            <img
              className="h-[27px] w-[27px] relative object-cover min-h-[27px]"
              loading="lazy"
              alt=""
              src="/landing/20240809-124049-1@2x.png"
            />
            <div className="relative font-semibold mq450:text-lg">
              Image To Text
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pl-0 pr-[506px] gap-5 mq450:pr-[126px] mq450:box-border mq975:pr-[253px] mq975:box-border">
            <img
              className="h-[27px] w-[27px] relative object-cover min-h-[27px]"
              loading="lazy"
              alt=""
              src="/landing/20240809-124049-1@2x.png"
            />
            <div className="relative font-semibold mq450:text-lg">
              Image to Mathematical Expression
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;

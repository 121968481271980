import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type FeatureItemType = {
  className?: string;
  icbaselineFolder?: string;
  folderManagement?: string;
  keepYourNotesOrganizedAnd?: string;

  /** Style props */
  propWidth?: CSSProperties["width"];
  propWidth1?: CSSProperties["width"];
  propHeight?: CSSProperties["height"];
  propDisplay?: CSSProperties["display"];
};

const FeatureItem: FunctionComponent<FeatureItemType> = ({
  className = "",
  propWidth,
  icbaselineFolder,
  propWidth1,
  folderManagement,
  keepYourNotesOrganizedAnd,
  propHeight,
  propDisplay,
}) => {
  const frameDivStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const icbaselineFolderIconStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  const keepYourNotesStyle: CSSProperties = useMemo(() => {
    return {
      height: propHeight,
      display: propDisplay,
    };
  }, [propHeight, propDisplay]);

  return (
    <div
      className={`flex-1 overflow-hidden flex flex-col items-start justify-start gap-2.5 min-w-[319px] max-w-full text-left text-3xl text-black font-inter ${className}`}
    >
      <div
        className="rounded-mini bg-cornflowerblue overflow-hidden flex flex-row items-center justify-center p-2.5"
        style={frameDivStyle}
      >
        <img
          className="h-[30px] w-[30px] relative overflow-hidden shrink-0"
          loading="lazy"
          alt=""
          src={icbaselineFolder}
          style={icbaselineFolderIconStyle}
        />
      </div>
      <div className="relative font-semibold mq450:text-lg">
        {folderManagement}
      </div>
      <div
        className="self-stretch h-[81px] relative inline-block shrink-0 mq450:text-lg"
        style={keepYourNotesStyle}
      >
        {keepYourNotesOrganizedAnd}
      </div>
    </div>
  );
};

export default FeatureItem;

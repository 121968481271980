import { FunctionComponent, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store"; // Adjust the import path as necessary
import { logout } from "../../redux/features/auth/authSlice";
import MenuBar from "../sidebar/MenuBar";
import PortalDrawer from "../popup/PortalDrawer";

const TopNavBar: FunctionComponent = () => {
  const [isMenuCustomerBarOpen, setMenuCustomerBarOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const user = useSelector((state: RootState) => state.user.user);

  const openMenuCustomerBar = useCallback(() => {
    if (isLoggedIn) {
      setMenuCustomerBarOpen(true);
    }
  }, [isLoggedIn]);

  const closeMenuCustomerBar = useCallback(() => {
    setMenuCustomerBarOpen(false);
  }, []);

  const handleLogout = async () => {
    await dispatch(logout());
    window.location.reload(); // Manually refresh the page after logout
  };

  const displayName =
    user?.firstName && user?.lastName
      ? `${user.firstName} ${user.lastName}`
      : user?.username || "Guest";

  const displayRole = (role: string) => {
    return role.charAt(0).toUpperCase() + role.slice(1);
  };

  const today = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <div className="self-stretch bg-white box-border overflow-hidden flex flex-row items-center justify-between py-2.5 px-5 min-w-[550px] text-left text-13xl-5 text-strong font-inter border-b-[0.9px] border-solid border-menu-line-grey">
        <div className="self-stretch w-[269px] flex flex-row items-center justify-start gap-[20px]">
          {isLoggedIn && (
            <button
              className="cursor-pointer [border:none] p-0 bg-[transparent] w-[45px] relative h-[45px]"
              onClick={openMenuCustomerBar}
            >
              <img alt="" src="/menu/menuBtn.png" />
            </button>
          )}
          <div className="self-stretch overflow-hidden flex flex-row items-center justify-start py-0 px-2.5 border-r-[0.9px] border-solid border-menu-line-grey">
            <div className="flex flex-row items-center justify-start gap-[8.5px]">
              <img
                className="w-[41px] relative h-[41px] object-cover"
                alt=""
                src="/logo/logo.png"
              />
              <div className="relative">YouWo</div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-[26px] text-sm-7">
          {user && (
            <div className="flex flex-col items-end justify-center gap-[3px]">
              <div className="relative">{displayName}</div>
              <b className="relative">{displayRole(user.role)}</b>
            </div>
          )}
          <div className="rounded-[5.12px] overflow-hidden flex flex-row items-center justify-center p-2.5 text-black-2 border-[0.9px] border-solid border-menu-line-grey">
            <div className="relative">{today}</div>
          </div>
          {isLoggedIn && (
            <button
              className="cursor-pointer [border:none] p-0 bg-[transparent] w-[25.6px] relative h-[25.6px] overflow-hidden shrink-0"
              onClick={handleLogout}
            >
              <img alt="" src="/navbar/logoutBtn.svg" />
            </button>
          )}
        </div>
      </div>
      {isMenuCustomerBarOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeMenuCustomerBar}
        >
          <MenuBar onClose={closeMenuCustomerBar} />
        </PortalDrawer>
      )}
    </>
  );
};

export default TopNavBar;

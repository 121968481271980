import { FunctionComponent } from "react";
import FeatureItem from "./FeatureItem";

export type FeatureType = {
  className?: string;
};

const Feature: FunctionComponent<FeatureType> = ({ className = "" }) => {
  return (
    <section
      className={`self-stretch overflow-hidden flex flex-row items-center justify-center py-[106px] px-5 box-border max-w-full text-left text-3xl text-black font-inter mq450:pt-[69px] mq450:pb-[69px] mq450:box-border ${className}`}
    >
      <div className="w-[1315px] flex flex-row flex-wrap items-center justify-start gap-5 max-w-full">
        <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-2.5 min-w-[319px] max-w-full">
          <div className="rounded-mini bg-cornflowerblue overflow-hidden flex flex-row items-center justify-center p-2.5">
            <img
              className="h-[30px] w-[30px] relative"
              loading="lazy"
              alt=""
              src="/landing/cloud.svg"
            />
          </div>
          <div className="relative font-semibold mq450:text-lg">
            Cloud Storage
          </div>
          <div className="self-stretch relative text-xl mq450:text-base">
            Never lose a note again with our secure, cloud-based storage,
            accessible from anywhere.
          </div>
        </div>
        <FeatureItem
          icbaselineFolder="/landing/icbaselinefolder.svg"
          folderManagement="Folder Management"
          keepYourNotesOrganizedAnd="Keep your notes organized and clutter-free with easy folder categorization."
        />
        <FeatureItem
          propWidth="50px"
          icbaselineFolder="/landing/fontistodollar.svg"
          propWidth1="15px"
          folderManagement="Free Tier Forever!"
          keepYourNotesOrganizedAnd="Enjoy access to essential features for free, forever, supporting your educational journey."
          propHeight="unset"
          propDisplay="unset"
        />
      </div>
    </section>
  );
};

export default Feature;

import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess: FunctionComponent = () => {
  const navigate = useNavigate();

  const onSignInClick = () => {
    navigate("/sign-in");
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-background-grey">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-semibold mb-4">Password Reset Success!</h1>
        <p className="mb-6">Return to app to continue signing in.</p>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;


export enum UserRole {
  User = 'User',
  Admin = 'Admin',
  // Include other roles as needed
}

export interface UserState {
  user: User | null;
  isLoading: boolean;
  error: any;
}

export interface User {
  id: number;
  email: string;
  username: string;
  discriminator: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  createdAt: Date;
  lastLogin: Date | null;
  isActive: boolean;
  emailVerified: boolean;
  role: UserRole;
  settings: UserSettings;
  subscription: UserSubscription;
  conversationId: number | null;
}

export interface UserSettings {
  defaultFolderId: number | null;
  defaultPromptId: number | null;
  AIType: string;
  fontSize: string;
  speechLanguage: string;
  timezone?: string;
}

export interface Address {
  street: string;
  zip: string;
  province: string;
  city: string;
}

export interface UserMetaData {
  firstname: string;
  lastname: string;
  gender: string;
  dateOfBirth: Date;
}

export interface UserSubscription {
  membership: string; // Adjust based on your enum
  membershipExpiresAt: Date | null;
  membershipType: string; // e.g. monthly, yearly
  recurring: boolean;
  paymentMethod: string;
  paymentStatus: string;
}

export interface UpdateUserDto {
  username?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  settings?: UserSettings;
  userMetaData?: UserMetaData;
  address?: Address;
}


export interface CreateUserDto {
  username?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  settings?: UserSettings;
  userMetaData?: UserMetaData;
  address?: Address;
}
